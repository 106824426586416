<template>
  <div>
    <perfil-empresa
      v-if="mostarCadastroEmpresa"
      @fecharCadastroEmpresa="fecharCadastroEmpresa"
      :idEmpresa="idEmpresaa"
      :nomePreenchido="nomePreenchido"
      :cnpjPreenchido="cnpjPreenchido"
      :usuarioPreenchido="usuarioPreenchido"
    ></perfil-empresa>
    <main :class="{ sidebar: true, open: isSidebarOpen }">
      <div class="logo_details">
        <img
          v-if="!isSidebarOpen"
          class="style-img"
          src="../../DataSendImagens/logomenor.svg"
          alt="Ícone"
        />
        <div v-if="isSidebarOpen" class="img-sidebar-container">
          <img src="../../DataSendImagens/Logo.svg" alt="Logo" />
        </div>
        <div
          style="display: flex; color: white; position: relative; width: 50px"
        >
          <button
            class="button-openA"
            v-if="!isSidebarOpen"
            :class="closeBtnClass"
            @click="toggleSidebar"
          >
            <i id="icon-open" class="fa-solid fa-angles-right"></i>
          </button>
          <button
            class="button-openF"
            v-if="isSidebarOpen"
            :class="closeBtnClass"
            @click="toggleSidebar"
          >
            <i id="icon-close" class="fa-solid fa-angles-left"></i>
          </button>
        </div>
      </div>
      <ul class="nav-list">
        <p class="traco"></p>
        <li class="link" @click="Trocardepagina('/userScreen')">
          <RouterLink to="/userScreen" active-class="active-link">
            <i id="icon" class="fa-solid fa-user"></i>
            <span class="link_name">Usuários</span>
          </RouterLink>
          <span class="tooltip">Usuários</span>
        </li>
        <li class="link" @click="Trocardepagina('/officeScreen')">
          <RouterLink to="/officeScreen" active-class="active-link">
            <i id="icon" class="fa-solid fa-clock"></i>
            <span class="link_name">Expedientes</span>
          </RouterLink>
          <span class="tooltip">Expedientes</span>
        </li>
        <li class="link" v-if="auth" @click="Trocardepagina('/companyScreen')">
          <RouterLink to="/companyScreen" active-class="active-link">
            <i id="icon" class="fa-solid fa-building"></i>
            <span class="link_name">Empresas</span>
          </RouterLink>
          <span class="tooltip">Empresas</span>
        </li>
        <li class="link" @click="Trocardepagina('/configurationScreen')">
          <RouterLink to="/configurationScreen" active-class="active-link">
            <i id="icon" class="fa-solid fa-gear"></i>
            <span class="link_name">Conexão</span>
          </RouterLink>
          <span class="tooltip">Conexão</span>
        </li>
        <li class="link" @click="Trocardepagina('/lockScreen')">
          <RouterLink to="/lockScreen" active-class="active-link">
            <i id="icon" class="fa-solid fa-shield"></i>
            <span class="link_name">Bloqueados</span>
          </RouterLink>
          <span class="tooltip">Bloqueados</span>
        </li>
        <li class="link" @click="Trocardepagina('/chatbotScreen')">
          <RouterLink to="/chatbotScreen" active-class="active-link">
            <i id="icon" class="fa-solid fa-comment-dots"></i>
            <span class="link_name">Chatbot</span>
          </RouterLink>
          <span class="tooltip">Chatbot</span>
        </li>
      </ul>
      <ul class="finally-sidebar">
        <p class="traco"></p>
        <li class="link" @click="PerfilEmpresa">
          <RouterLink to="">
            <div @click="PerfilEmpresa">
              <i id="icon" class="fa-solid fa-building"></i>
            </div>
            <span class="link_name">{{ truncateText(nameUser, 9) }}</span>
          </RouterLink>
          <span class="tooltip">{{ nameUser }}</span>
        </li>
        <li class="link" @click="exit">
          <a href="#" class="logout-link">
            <i style="margin-left: 0.15rem;" id="icon" class="fa fa-sign-out" aria-hidden="true"></i>
            <span class="link_name">Sair</span>
          </a>
          <span class="tooltip">Sair</span>
        </li>
      </ul>
    </main>
  </div>
</template>

<script>
import iconeEmpresa from "@/components/siderbar/iconeEmpresa.vue";
import CadatroEmpresa from "./CadastroEmpresa.vue";
import PerfilEmpresa from "./PerfilEmpresa.vue";
const url = process.env.VUE_APP_API_URL;

export default {
  name: "siderbar",
  components: { iconeEmpresa, CadatroEmpresa, PerfilEmpresa },
  data() {
    return {
      isSidebarOpen: false,
      auth: false,
      mostarCadastroEmpresa: false,
      idEmpresaa: null,
      nameUser: null,
      modoEdicaoSenha: true,
      nomePreenchido: true,
      cnpjPreenchido: true,
      usuarioPreenchido: true,
      exibirTooltipFlag: false,
      button_position_left: null,
      button_position_top: null,
    };
  },
  computed: {
    closeBtnClass() {
      return this.isSidebarOpen ? "bx-menu-alt-right" : "bx-menu";
    },
  },
  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    truncateText(text, maxLength) {
      if (!text) return "";
      return text.length > maxLength
        ? text.substring(0, maxLength) + "..."
        : text;
    },
    logout() {
      localStorage.clear();
      location.reload();
    },

    async exit() {
      await this.$axios.post(url + "/logout");
      // localStorage.clear();
      // location.reload();
      this.$router.push("/");
    },
  
  Trocardepagina(id) {
    this.$router.push(id);
  },
  PerfilEmpresa() {
    this.mostarCadastroEmpresa = true;
  },
  // abirEdicaoSenha() {
  //   this.modoEdicaoSenha = true;
  //   this.mostarCadastroEmpresa = true;
  // },
  fecharCadastroEmpresa() {
    this.mostarCadastroEmpresa = false;
  },
},
  created() {
    try {
      const userJSON = localStorage.getItem("User");
      const userObj = JSON.parse(userJSON);
      this.nameUser = userObj.User;
      this.idEmpresaa = userObj.Id;
      const auth = this.$store.state.devAut;
      this.auth = auth;
    } catch (error) {}
  },
};
</script>

<style>
:root {
  --sidebar-item-active: #b3b3b3;
  --color-white: #b3b3b3;
  --color-body: #b3b3b3;
  --color-light: #b3b3b3;
}
</style>
<style scoped>
#icon-open,
#icon-close {
  font-size: 1.2rem;
  color: black;
  justify-items: center;
}

.button-openA {
  position: absolute;
  left: 14px;
  transform: translateY(-50%);
  background-color: white;
  border: none;
  height: 42px;
  width: 28px;
  border-radius: 3px;
  box-shadow: 1px 1px 2px 2px #d9d9d9;
  cursor: pointer;
}
.button-openF {
  position: absolute;
  left: 50px;
  transform: translateY(-50%);
  background-color: white;
  border: none;
  height: 42px;
  width: 28px;
  border-radius: 3px;
  box-shadow: 1px 1px 2px 2px #d9d9d9;
  cursor: pointer;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
/* .style-img {
  height: 16vh;
  width: 12vw;
  margin-right: 10%;
} */
.style-img {
  height: 150%;
  width: 110%;
  margin-right: 10%;
}

.img-sidebar-container img {
  height: auto;
  max-width: 150%;
}
#icon-open {
  justify-content: center;
  font-size: 1rem;
  color: black;
  margin-left: 4px;
}
#icon-close {
  justify-content: center;
  font-size: 1rem;
  color: black;
  margin-left: 3px;
}
body {
  min-height: 100vh;
}
#icon {
  font-size: 1.5rem;
  color: black;
  padding: 0px 1px;
}
#button-Exit {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.sidebar-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

main {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.nav-list {
  flex: 1;
}

.sidebar-bottom {
  margin-top: auto;
}

.sidebar {
  min-height: 100vh;
  width: 105px;
  padding: 15px 25px;
  background-color: var(--color-default);
  box-shadow: 1px 1px 2px 2px #d9d9d9;
  transition: all 0.5s ease;
  position: fixed;
  top: 0;
  left: 0;
}
.link {
  display: flex;
  padding: 2px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

/* .active-link {
  background-color: var(--sidebar-item-active);
  color: var(--color-white);
  border-radius: 5px;
} */
.link:hover {
  background-color: #b3b3b3;
}

.link:focus-within {
  background-color: #b3b3b3;
}
.traco {
  display: flex;
  width: 100%;
  border: 1px solid #b3b3b3;
}
.sidebar.open {
  width: 175px;
}

.sidebar .logo_details {
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
}

.sidebar .logo_details .icon {
  opacity: 0;
  transition: all 0.5s ease;
}

.sidebar .logo_details .logo_name {
  color: var(--color-white);
  font-size: 22px;
  font-weight: 600;
  opacity: 0;
  transition: all 0.5s ease;
}

.sidebar.open .logo_details .icon,
.sidebar.open .logo_details .logo_name {
  opacity: 1;
}

.sidebar .logo_details i {
  position: relative;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 23px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
}

.sidebar.open .logo_details i {
  text-align: right;
}

.sidebar i {
  color: var(--color-white);
  height: 60px;
  line-height: 60px;
  min-width: 50px;
  font-size: 25px;
  text-align: center;
  padding-right: 30px;
}

.sidebar .nav-list {
  margin-top: 5px;
  height: 100%;
  width: 115%;
}
.sidebar .finally-sidebar {
  margin-top: 16vh;
  height: 100%;
  width: 115%;
}

.sidebar li {
  position: relative;
  margin: 20px 0;
  list-style: none;
}

.sidebar li .tooltip {
  position: absolute;
  top: -20px;
  left: calc(100% + 15px);
  z-index: 3;
  background-color: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 6px 14px;
  font-size: 15px;
  font-weight: 400;
  border-radius: 5px;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  z-index: 10;
}

.sidebar li:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
  top: 50%;
  transform: translateY(-50%);
}

.sidebar.open li .tooltip {
  display: none;
}

.sidebar input {
  font-size: 15px;
  color: var(--color-white);
  font-weight: 400;
  outline: none;
  height: 35px;
  width: 35px;
  border: none;
  border-radius: 5px;
  background-color: var(--color-second);
  transition: all 0.5s ease;
}

.sidebar input::placeholder {
  color: var(--color-light);
}

.sidebar.open input {
  width: 100%;
  padding: 0 20px 0 50px;
}

.sidebar .bx-search {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 22px;
  background-color: var(--color-second);
  color: var(--color-white);
}

.sidebar li a {
  display: flex;
  height: 100%;
  width: 100%;
  padding: 0px px;
  align-items: center;
  text-decoration: none;
  background-color: var(--color-default);
  position: relative;
  transition: all 0.5s ease;
  z-index: 12;
}

.sidebar li a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scaleX(0);
  background-color: var(--color-white);
  border-radius: 5px;
  transition: transform 0.3s ease-in-out;
  transform-origin: left;
  z-index: -2;
}

.sidebar li a:hover::after {
  transform: scaleX(1);
  color: var(--color-default);
}

.sidebar li a .link_name {
  color: var(--color-white);
  font-size: 1rem;
  font-weight: 400;
  white-space: nowrap;
  pointer-events: auto;
  transition: all 0.4s ease;
  pointer-events: none;
  opacity: 0;
  color: black;
}
/* .sidebar li a:hover .link_name {
  background-color: #b3b3b3;
  border-radius: 5px;
  height: 100%;
  width: 100%;
} */
.sidebar li a:hover .link_name,
.sidebar li a:hover i {
  transition: all 0.5s ease;
  color: rgba(244, 242, 242, 0.873);
}

.sidebar.open li a .link_name {
  opacity: 1;
  pointer-events: auto;
}

.sidebar li i {
  height: 35px;
  line-height: 35px;
  font-size: 18px;
  border-radius: 5px;
  padding-right: 20px;
}

.sidebar li.profile {
  position: fixed;
  height: 60px;
  width: 78px;
  left: 0;
  bottom: -8px;
  padding: 10px 14px;
  overflow: hidden;
  transition: all 0.5s ease;
}
.active-link {
  background-color: var(--sidebar-item-active);
  color: var(--color-white);
  border-radius: 5px;
}

.active-link i {
  color: var(--color-white);
  background-color: #b3b3b3;
}
.active-link li {
  color: var(--color-white);
  background-color: #b3b3b3;
}

.active-link .link_name {
  color: var(--color-white);
}

.sidebar li a.active-link::after {
  transform: scaleX(1);
  color: var(--color-white);
}
.sidebar.open li.profile {
  width: 20px;
}
.sidebar .profile .profile_details {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.sidebar li img {
  height: 45px;
  width: 45px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}

.sidebar li.profile .name,
.sidebar li.profile .designation {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-white);
  white-space: nowrap;
}

.sidebar li.profile .designation {
  font-size: 12px;
}

.sidebar .profile #log_out {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-color: var(--color-second);
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.5s ease;
}

.sidebar.open .profile #log_out {
  width: 50px;
  background: none;
}

.home-section {
  position: relative;
  background-color: var(--color-body);
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}

.home-section .text {
  display: inline-block;
  color: var(--color-default);
  font-size: 25px;
  font-weight: 500;
  margin: 18px;
}

.sidebar.open ~ .home-section {
  left: 250px;
  width: calc(100% - 250px);
}
</style>
