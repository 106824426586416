const axios = require('axios');

const url2 = process.env.VUE_APP_API_URL;



async function fetchDados() {
  try {
    const response = await axios.get(url2 + '/empresa', {
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    return null;
  }
}

async function deleteDados(id) {
  try {
    const response = await axios.delete(url2 + '/logout/' + id, {
      withCredentials: true});
    return response.data;
  } catch (error) {
    return null;
  }
}

export { fetchDados, deleteDados };
