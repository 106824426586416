<script>
import CadastroUsuario from "./CadastroUsuario.vue";
import ConfirmationModalVue from "../components/Comfirmacao/ConfirmationModal.vue";
import { AlertDataSend } from "../components/alert";
import SelectSearch from "../components/Select-Search/SelectSearch.vue";
const url = process.env.VUE_APP_API_URL;

export default {
  components: { CadastroUsuario, ConfirmationModalVue, SelectSearch },
  data() {
    return {
      dados: [],
      exibirCadastro: false,
      selectedRows: [],
      SelectedIds: [],
      todos_itens: false,
      DadosPesquisa: "",
      dadosPesquisado: null,
      statusPesquisa: "nadaPesquisado",
      estaOrdemAlfabetica: true,
      showDeleteConfirmation: false,
      userToDelete: null,
      limiteUsuario: null,
      qtd_usuario: null,
      contagemUsuario: null,
      backgroundColor: "white",
      textForModal: "Deseja realmente excluir este usuário?",
      idEmpresa: null,
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    const localStorageObject = JSON.parse(localStorage.getItem("User"));
    this.idEmpresa = localStorageObject.Id;
    const { data } = await this.$axios.get(url + "/empresa", {
      params: {
        id: this.idEmpresa,
      },
    });

    data.usuario.sort((a, b) => {
      return a.nome.toLowerCase().localeCompare(b.nome.toLowerCase());
    });
    this.limiteUsuario = data.qtd_usuario;
    this.dados = data.usuario;
    this.loading = false;
  },
  methods: {
    alert() {
      AlertDataSend("Ola");
    },
    ordernarOrdemAlfabetica() {
      const ordenado = this.dados.slice();

      if (
        this.estaOrdemAlfabetica === null ||
        this.estaOrdemAlfabetica === false
      ) {
        ordenado.sort((a, b) =>
          a.nome.toUpperCase().localeCompare(b.nome.toUpperCase())
        );
        this.estaOrdemAlfabetica = true;
      } else {
        ordenado.sort((a, b) =>
          b.nome.toUpperCase().localeCompare(a.nome.toUpperCase())
        );
        this.estaOrdemAlfabetica = false;
      }

      this.dados = ordenado;
    },
    showDeleteModal(user) {
      if (this.selectedRows.length > 0) {
        this.textForModal = "Deseja realmente excluir estes usuários?";
      }
      this.userToDelete = {
        ...user,
        backgroundColor: "rgba(92, 90, 90, 0.1)",
      };
      this.showDeleteConfirmation = true;
    },
    deleteUserConfirmed() {
      if (this.selectedRows.length !== 0) {
        for (let i = 0; i < this.selectedRows.length; i++) {
          this.btn_rm(this.selectedRows[i]);
        }
        this.selectedRows = [];
        this.showDeleteConfirmation = false;
        // location.reload()
      } else {
        this.btn_rm(this.userToDelete.idusuario);
        this.showDeleteConfirmation = false;
        // location.reload()
      }
    },
    deleteUserCanceled() {
      if (this.userToDelete) {
        this.userToDelete.backgroundColor = "";
      }
      this.showDeleteConfirmation = false;
    },
    removerTodos() {
      for (const item of this.selectedRows) {
        this.btn_rm(item, false);
      }
    },
    selecionartodos() {
      this.todos_itens = !this.todos_itens;
      if (this.todos_itens) {
        const dados = this.dados;
        this.selectedRows = dados.map((dados) => dados.idusuario);
      } else {
        this.selectedRows = [];
      }
    },
    async mostraCadastro() {
      if (this.dados.length == this.limiteUsuario) {
        AlertDataSend("Limite de usuário atingido");
        return;
      }
      this.$router.push({ name: "cadastrousuario" });
    },

    btn_edit(id) {
      this.$router.push({ name: "cadastrousuario", query: { id: id } });
    },
    btn_rm(id, elementos) {
      this.$axios
        .delete(url + "/usuario", {
          params: {
            id: id,
            idCompany: this.idEmpresa,
          },
        })
        .then((response) => {
          response.data.sort((a, b) => {
            return a.nome.toLowerCase().localeCompare(b.nome.toLowerCase());
          });
          this.dados = response.data;
        })
        .catch((error) => {
          console.error("Erro ao excluir o usuário:", error);
          AlertDataSend("Erro ao deletar o registro:");
        });
    },
    selecionarUsuario(usuarioId) {
      const index = this.selectedRows.indexOf(usuarioId);
      if (index === -1) {
        this.selectedRows.push(usuarioId);
      } else {
        this.selectedRows.splice(index, 1);
      }
      const usuario = this.dados.find((user) => user.idusuario === usuarioId);
      if (usuario) {
        usuario.backgroundColor = usuario.backgroundColor
          ? ""
          : "rgba(92, 90, 90, 0.1)";
      }
    },
  },

  computed: {
    atualizarPesquisa() {
      if (this.DadosPesquisa.length === "") {
        this.statusPesquisa === "nadaPesquisado";
      } else {
        const objetosFiltrados = [];
        const filtroTratado = this.DadosPesquisa.toLowerCase();
        for (const item of this.dados) {
          const nomeTratado = item.nome.toLowerCase();
          if (nomeTratado.startsWith(filtroTratado))
            objetosFiltrados.push(item);
          this.statusPesquisa === "dadoEncontrado";
        }
        if (objetosFiltrados.length === 0) {
          this.statusPesquisa === "nadaEncontrado";
        }
        return objetosFiltrados;
      }

      if (this.estaOrdemAlfabetica) {
        return this.ordernarOrdemAlfabetica();
      } else {
        this.ordernarOrdemAlfabetica();
      }
    },
  },
};
</script>
<template>
  <main class="base">
    <header class="caixaTitulo">
      <div class="caixaTitulo_titulo">Usuários</div>
      <div>
        <button class="caixaTitulo_botao" @click="mostraCadastro">
          <i class="fa-solid fa-plus"></i>
          Adicionar Usuário
          <div class="DicaTexto">Adicionar Novo Usuário</div>
        </button>
      </div>
    </header>
    <ConfirmationModalVue
      class="modal-style"
      v-if="showDeleteConfirmation"
      @confirmed="deleteUserConfirmed"
      @canceled="deleteUserCanceled"
      :text="textForModal"
    >
    </ConfirmationModalVue>
    <section class="conteudo">
      <div class="tabela">
        <div class="cont-usuario">
          <span>Usuários: {{ dados.length }}/{{ limiteUsuario }}</span>
        </div>
        <tr>
          <th>
            <div style="display: flex">
              <div class="icon-style">
                <!-- <i class="fa-solid fa-filter"></i> -->
              </div>
              <input
                type="text"
                v-model="DadosPesquisa"
                placeholder="Pesquisar usuários"
                class="pesquisa"
              />
              <button class="botaoPesquisa">
                <i class="fas fa-search"></i></button
              ><br /><br />
            </div>
          </th>
        </tr>
        <table cellspacing="0">
          <thead>
            <tr class="nomedascolunas">
              <th style="text-align: center">
                <input
                  type="checkbox"
                  v-model="todos_itens"
                  @click="selecionartodos"
                  style="font-size: 1.4rem"
                />
              </th>
              <th
                style="
                  width: 5%;
                  align-items: center;
                  text-align: center;
                  margin-left: 3.5vh;
                "
              >
                Editar
              </th>
              <th @click="ordernarOrdemAlfabetica()">
                <span class="style-name"
                  >Nome de usuário
                  <span v-if="estaOrdemAlfabetica">
                    <i class="fa-solid fa-arrow-up-wide-short"></i
                  ></span>
                  <span v-else
                    ><i class="fa-solid fa-arrow-down-wide-short"></i></span
                ></span>
              </th>
              <th>Expediente</th>
              <th>Status</th>
              <th style="width: 10%; align-items: center">
                <span
                  style="
                    width: 10%;
                    align-items: center;
                    text-align: center;
                    margin-left: 3.5vh;
                  "
                >
                  Excluir
                </span>
                <button
                  class="btn_rm"
                  @click="showDeleteModal()"
                  style="
                    font-size: 1rem;
                    color: #2bb542;
                    font-weight: bold;
                    text-align: center;
                    margin-left: -6.3vw;
                  "
                  v-if="selectedRows.length"
                >
                  ({{ selectedRows.length }})
                  <i class="fa-solid fa-trash"></i>
                </button>
              </th>
            </tr>
          </thead>
          <thead>
            <tr class="naoSelecionavel">
              a
            </tr>
          </thead>
          <tbody
            v-if="atualizarPesquisa"
            v-for="(row, indexFiltro) in atualizarPesquisa"
            :key="indexFiltro"
          >
            <tr
              class="back-color"
              :style="{
                backgroundColor:
                  userToDelete && userToDelete.idusuario === row.idusuario
                    ? userToDelete.backgroundColor
                    : '',
              }"
            >
              <td
                style="text-align: center; border-left: none; border-top: none"
              >
                <input
                  type="checkbox"
                  v-model="selectedRows"
                  :value="row.idusuario"
                />
              </td>
              <td style="border-right: 1px solid #b3b3b3; text-align: center">
                <button
                  style="
                    width: 100%;
                    cursor: pointer;
                    border: none;
                    background-color: transparent;
                  "
                  @click="btn_edit(row.idusuario)"
                >
                  <i class="fa fa-pencil"></i>
                </button>
              </td>
              <td style="padding-left: 8px">{{ row.nome }}</td>
              <td
                :class="{
                  'expediente-conectado': row.nome_expediente,
                  'sem-expediente': !row.nome_expediente,
                }"
              >
                <span v-if="row.nome_expediente"
                  >{{ row.nome_expediente }}
                </span>
                <span v-else>Nenhum</span>
              </td>
              <!-- <td>{{ row.nome_expediente }}</td> -->
              <td>{{ row.status }}</td>
              <td style="width: 5%; text-align: center">
                <button
                  style="
                    width: 100%;
                    cursor: pointer;
                    border: none;
                    background-color: transparent;
                  "
                  @click="showDeleteModal(row)"
                >
                  <i id="icon-trash" class="fa-solid fa-xmark"></i>
                </button>
              </td>
            </tr>
            <tr class="line">
              <td class="naoSelecionavel">d</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr class="line_vazia">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
          <tbody v-else v-for="(row, index) in dados" :key="index">
            <tr
              :style="{
                backgroundColor:
                  userToDelete && userToDelete.idusuario === row.idusuario
                    ? userToDelete.backgroundColor
                    : '',
              }"
            >
              <td id="teste" style="text-align: center; border-left: none">
                <input
                  type="checkbox"
                  @click="selecionarUsuario(row.idusuario)"
                />
              </td>
              <td>{{ row.nome }}</td>
              <td>{{ row.nome_expediente }}</td>
              <td>{{ row.status }}</td>
              <td style="border-right: 1px solid #b3b3b3; text-align: center">
                <button class="btn_edit" @click="btn_edit(row.idusuario)">
                  <i class="fa fa-pencil"></i>
                </button>
              </td>
              <td style="border-left: none; width: 5%; text-align: center">
                <button class="btn_rm" @click="btn_rm(row.idusuario, false)">
                  <i class="fa-solid fa-x"></i>
                </button>
              </td>
            </tr>
            <tr class="line">
              <td class="naoSelecionavel">d</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr class="line_vazia">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
          <!-- <div> 
          <button class="btn-info">
            <i class="fa-solid fa-circle-info"></i>
          </button>
        </div> -->
        </table>
        <div v-if="atualizarPesquisa.length === 0" class="nadaEncontrado">
          <div>
            Nada foi encontrado
            <i class="fa-solid fa-plug-circle-exclamation"></i>
          </div>
        </div>
        <div class="overlay" v-if="loading">
          <i id="icon-style" class="fa-solid fa-gear"></i>
          <i id="icon-stylee" class="fa-solid fa-gear"></i>
        </div>
      </div>
    </section>
  </main>
</template>

<style scoped>
.tabela {
  width: 100%;
  height: 70vh;
  font-family: Arial, Helvetica, sans-serif;
  overflow-y: scroll;
  overflow-x: scroll;
}
.modal-style {
  margin-left: 28rem;
}
#icon-trash {
  color: red;
  font-size: 1rem;
}
h1 {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.overlay .fa-gear {
  animation: spin 1s linear infinite;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

#icon-style {
  align-items: center;
  font-size: 3rem;
  color: white;
}

#icon-stylee {
  font-size: 2rem;
  color: white;
  align-items: end;
}

.caixaTitulo_disparar {
  margin-left: 43vw;
  border: none;
  background-color: rgba(185, 184, 184, 0.426);
  color: black;
  height: 5vh;
  width: 10vw;
  border-radius: 3px;
  cursor: pointer;
}

.caixaTitulo_disparar {
  position: relative;
  display: inline-block;
}

.caixaTitulo_disparar:hover .DicaTexto {
  visibility: visible;
}

#color-icon {
  color: green;
}

th,
td {
  padding: 2px;
  border-spacing: 35px;
}

.sem-expediente {
  color: rgba(92, 90, 90, 0.5);
}

table {
  width: 100%;
}

.style-name {
  margin-left: 5px;
}

.btn-info {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: green;
  font-size: 1.1rem;
  margin-left: 67vw;
}

.caixaTitulo_botao:hover {
  background-color: #2bb542e1;
}

.back-color:hover {
  background-color: rgba(92, 90, 90, 0.1);
}

#btn_bin {
  border: none;
  color: green;
  cursor: pointer;
  margin-left: -105%;
  background-color: transparent;
}

.cont-usuario {
  display: flex;
  flex-direction: column;
  margin-left: 89%;
  color: rgba(92, 90, 90, 0.5);
}

thead {
  border-bottom: 1px solid #b3b3b3;
}

tr td {
  border-left: 1px solid #b3b3b3;
}

.nomedascolunas {
  text-align: left;
  color: #2bb542;
  font-family: initial;
}

thead {
  margin-bottom: 5px;
}

.nomedascolunas th {
  border-bottom: 1px solid #2bb542;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 0;
}

.pesquisa {
  width: 17vw;
  height: 30px;
  padding-left: 5px;
  display: flex;
  margin-left: 2vw;
}

.icon-style {
  padding-left: 2vh;
}

.line {
  padding: 0;
  color: transparent;
  border-left: none;
  border-right: none;
}

.caixaTitulo_botao {
  position: relative;
  display: inline-block;
  border-radius: 3px;
}

.caixaTitulo_botao:hover .DicaTexto {
  visibility: visible;
}

.DicaTexto {
  position: absolute;
  visibility: hidden;
  font-size: 13px;
  width: 200px;
  background: rgba(37, 37, 37, 0.695);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 4px;
  bottom: 100%;
  left: 50%;
  margin-left: -100px;
  margin-bottom: 5px;
  transition-timing-function: ease;
  transition-duration: 0.2s;
}

.DicaTexto:after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(37, 37, 37, 0.695) transparent transparent transparent;
}

.line td {
  border-bottom: 1px solid #b3b3b3;
  border-left: none;
  height: 1px;
  padding: 0;
}

/* tippy-box[data-theme="tema-personalizado"] {
  background-color: #99b199; 
  color: #e31b1b; 
} */

.line_vazia td {
  height: 1px;
  border: none;
}

.naoSelecionavel {
  font-size: 2px;
  color: transparent;

  -webkit-touch-callout: none;
  /* iPhone OS, Safari */
  -webkit-user-select: none;
  /* Chrome, Safari 3 */
  -khtml-user-select: none;
  /* Safari 2 */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+ */
  user-select: none;
}

.botaoPesquisa {
  background-color: white;
  padding: 5px;
  border: 0px;
  cursor: pointer;
}

.nadaEncontrado {
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  text-align: center;
  color: #c1c1c1;
}
</style>
