<script>
export default {
  props: {
    type: String,
    index: Number,
    display: Boolean,
    total: Number,
    personalize: {
      selected: Boolean,
      iconPersonalize: Number,
    },
  },
  data() {
    return {
      DropdownOpen: false,
      dropdowns: {},
      icons: [],
      enviarPlanilha: [],
      enviarImagems: [],
      enviarTexto: [],
      modelIcon: [
        {
          id: 0,
          name: "Planilha",
          icon: "fa-solid fa-file-excel",
          type: ["table", "pivot-table"],
        },
        {
          id: 1,
          name: "Imagem",
          icon: "fa-solid fa-image",
          type: ["kpi", "sn-layout-container"],
        },
        {
          id: 2,
          name: "Texto",
          icon: "fa-solid fa-font",
          type: ["text-image", "text"],
        },
      ],
    };
  },
  async created() {
    await this.filterIcon();
    window.addEventListener("click", this.closeOnOutsideClick);
  },
  watch: {
    personalize(newValue) {
      if (newValue.selected === true) {
        this.filterIconAltered(newValue.iconPersonalize);
      } else {
        this.filterIcon();
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("click", this.closeOnOutsideClick);
  },
  methods: {
    async filterIcon() {
      if (this.personalize.selected === false || !this.personalize.selected) {
        if (this.type === "table" || this.type === "pivot-table") {
          this.icons = this.modelIcon.filter(
            (item) => !item.type.includes(this.type)
          );
        } else if (this.type) {
          this.icons = this.modelIcon.filter(
            (item) =>
              !item.type.includes(this.type) !== item.type.includes("table")
          );
        }
      } else {
        this.filterIconAltered(this.personalize.iconPersonalize);
      }
    },
    filterIconAltered(id) {
      if (this.type === "table" || this.type === "pivot-table") {
        this.icons = this.modelIcon.filter((item) => item.id !== id);
      } else {
        this.icons = this.modelIcon.filter(
          (item) => item.id !== id && item.type.includes("table")
        );
      }
    },
    togglePagesDropdown() {
      this.DropdownOpen = !this.DropdownOpen;
      this.$emit("update-display");
    },
    toggleDropdown(id) {
      this.DropdownOpen = false;
      this.dropdowns[id] = !this.dropdowns[id];
      this.$emit("selectedOpt", id, this.index);
      this.$emit("updateDataInArray", id, this.index);
    },
    filtrarEnvio() {
      this.enviarPlanilha = this.enviarPorTipo(0);
      this.enviarImagems = this.enviarPorTipo(1);
      this.enviarTexto = this.enviarPorTipo(2);
    },
    closeOnOutsideClick(event) {
      const isClickInsideComponent = this.$el.contains(event.target);
      if (!isClickInsideComponent) {
        this.DropdownOpen = false;
      }
    },
  },
};
</script>
<template>
  <div class="menu-bar">
    <ul>
      <li v-if="display" class="all-style" @click="togglePagesDropdown">
        <div class="button-onclik">
          <i class="fa-solid fa-caret-down"></i>
        </div>
        <div
          v-if="DropdownOpen"
          :class="['dropdown-menu', { 'open-up': shouldOpenUp }]"
        >
          <ul>
            <li
              class="dropdown-item"
              v-for="(icon, index) in icons"
              :key="index"
            >
              <a @click.prevent="toggleDropdown(icon.id)">
                <i id="icons" :class="icon.icon"></i>{{ icon.name }}
              </a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

  
<style scoped>
:root {
  --color-primary: #0073ff;
  --color-whitee: #100f0f;
  --color-black: #141d28;
  --color-black-1: #212b38;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
}

p {
  font-size: 17px;
}

#icons {
  color: #545556;
  font-size: 15px;
  margin-right: 5px;
  color: #2bb542;
}

#icon-open {
  font-size: 15px;
  margin-left: 45px;
}

#icon-openn {
  font-size: 15px;
  margin-left: 50px;
}

.button-onclik {
  color: black;
  font-size: 0.6rem;
}

.menu-bar {
  background-color: var(--color-black);
  height: 10px;
  width: 100%;
}

.menu-bar ul {
  list-style: none;
  display: block;
  margin: 2px;
}

.all-style {
  border: none;
}

ul li {
  border: 1px solid rgba(185, 184, 184, 0.747);
}

.menu-bar ul li {
  color: black;
  cursor: pointer;
  background-color: white;
  font-size: 18px;
}
.dropdown-item {
  padding: 3px;
}
.dropdown-menu li:hover {
  background-color: rgba(185, 184, 184);
}

.menu-bar ul li a {
  font-size: 18px;
  color: var(--color-whitee);
  text-decoration: none;
  transition: all 0.3s;
}

.menu-bar ul li a:hover {
  color: var(--color-primary);
}

.fas {
  float: right;
  margin-left: 10px;
  padding-top: 3px;
}

.dropdown-menu {
  position: absolute;
  z-index: 9;
}

.dropdown-menu.open-up {
  display: block;
  margin: 2px;
}

/* .menu-bar ul li:hover .dropdown-menu ul {
  display: block;
  margin: 2px;
}

.menu-bar ul li:hover .dropdown-menu ul li {
  width: 100px;
  padding: 5px;
}  */
</style>