const url = process.env.VUE_APP_API_URL;
import { instanceAxios } from '../../config/axios-config.js';
import axios from 'axios';

async function getUsuarioCadastrado(_routerQueryId) {
  return await
    axios.get(url + '/usuario/cadastrado', {
      params: {
        id: _routerQueryId,
      },
      withCredentials: true,
    })
      .then((response) => {
        const usuarioJaCadastrado = {
          nome: response.data.nome,
          email: response.data.email,
          numeroWhatsapp: response.data.contato,
          assistente: response.data.status,
          filtro: response.data.filtro,
          shooting: response.data.shooting,
          expediente: response.data.expediente
        };
        return usuarioJaCadastrado;
      })

      .catch((error) => {
        console.error("erro api", error);
      });
}

async function postCadastroBanco(user) {
  if (user.idusuario) {
    return await axios.put(url + '/usuario/cadastrado', user, { withCredentials: true }).then((response) => {
      return response
    }).catch((error) => {
      throw error
    })
  } else {
    return await axios.post(url + '/usuario', user, { withCredentials: true }).then((response) => {
      return response
    }).catch((error) => {
      throw error
    })

  }
}

async function getSheetByid(_routerQueryId) {
  const jsonData = { id: _routerQueryId }
  const panel = await axios.get(url + '/empresa/qlik', {
    params: {
      jsonData,
    },
    withCredentials: true,
  })

  return panel
}

async function getObjectByid(_routerQueryId, painelId, pastaId) {
  const objJson = {
    id: _routerQueryId,
    painel: painelId,
    sheet: pastaId,
  }

  const reqObject = await axios.get(url + '/empresa/qlik', {
    params: {
      objJson
    },
    withCredentials: true,
  })
  const objetos = await reqObject.data
  return objetos
}

async function getDisparo(qlik) {
  const response = await axios.get(url + '/shooting', {
    params: {
      qlik: qlik,
    },
    withCredentials: true
  })

  return response.data
}


async function postDisparo(objetoParaEnviarBanco) {
  await axios.post(url + '/shooting', objetoParaEnviarBanco, { withCredentials: true }).then((response) => {
    return response.data
  }
  )
}

export { getDisparo, postDisparo }

export { getUsuarioCadastrado, postCadastroBanco, getSheetByid, getObjectByid }
