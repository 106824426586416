<template>
  <div class="wrapper">
    <div
      class="select-btn"
      @click="openSelected"
      :class="{ disabled: abrirselect }"
    >
      <span>{{ selected }}</span>
      <i class="fas fa-angle-down" :class="{ rotated: showSelected }"></i>
    </div>
    <div class="content" v-if="showSelected">
      <div class="search">
        <input
          type="text"
          placeholder="Pesquisar"
          v-model="pesquisa"
          @change.prevent="executeSearch"
        />
      </div>
      <ul class="options">
        <li
          v-for="(option, index) in filteredData"
          :key="index"
          @click="updateSearch(option, index)"
          :class="{ 'selected-background': index === selectedIndex }"
          @mouseover="showTooltip(index, $event)"
          @mouseleave="hideTooltip"
          class="option-item"
        >
          {{ option.title }}
          <div
            v-if="tooltipIndex === index"
            class="tooltip"
            :style="{ top: tooltipY + 'px', left: tooltipX + 'px' }"
          >
            {{ option.title }}
          </div>
        </li>
      </ul>
      <button v-if="showLoadButton" id="button-load" @click="carregarMais">
        <i
          class="fa-solid fa-rotate-right"
          :class="{ rotated: showSelected, rotating: loading }"
        ></i>
        <div class="DicaTexto">Carregar Mais..</div>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectSearch",
  props: {
    options: [],
    selected: "",
    spinner: Boolean,
    showLoadButton: Boolean,
    loading: Boolean,
    abrirselect: Boolean,
    removeSelecao: Boolean,
  },
  watch: {
    conteudo(newVal) {
      if (!this.conteudos.includes(newVal)) {
        this.conteudos.push(newVal);
      }
    },
  },
  data() {
    return {
      showSelected: false,
      pesquisa: "",
      selectedIndex: null,
      tooltipIndex: null,
      tooltipX: 0,
      tooltipY: 0,
      // abrirselect: true,
    };
  },
  created() {
    window.addEventListener("click", this.closeOnOutsideClick);
  },
  beforeDestroy() {
    window.removeEventListener("click", this.closeOnOutsideClick);
  },
  computed: {
    filteredData() {
      if (!this.options || !Array.isArray(this.options)) {
        return [];
      } else {
        return this.options.filter((option) =>
          option.title.toLowerCase().includes(this.pesquisa.toLowerCase())
        );
      }
    },
  },
  methods: {
    showTooltip(index, event) {
    this.tooltipIndex = index;
    const target = event.target.getBoundingClientRect();
    this.tooltipX = target.right - 15; 
    this.tooltipY = target.top + window.scrollY - 28;
  },
  hideTooltip() {
    this.tooltipIndex = null;
  },
    openSelected() {
      if (this.showSelected === true) {
        this.__closeSelected();
        return;
      } else if (this.abrirselect) {
        return;
      } else {
        this.__openSelected();
      }
      // this.__openSelected()
    },
    /**
     * abre o select
     */
    __openSelected() {
      this.showSelected = true;
    },
    /**
     * fecha o select
     */
    __closeSelected() {
      this.showSelected = false;

      if (this.selected === undefined) {
        // emite o aviso de que não foi selecionado nenhuma opção
        this.$emit("without-input");
        this.$emit("update");
      }
    },
    updateSearch(option, index) {
      this.showSelected = false;
      if (
        (this.selectedIndex || this.selectedIndex === 0) &&
        this.selectedIndex === index
      ) {
        this.clearSelect();
        return;
      }
      this.selectedIndex = index;
      this.$emit("input", option);
      this.$emit("update");
    },
    clearSelect() {
      this.selectedIndex = null;
      this.$emit("without-input");
    },
    carregarMais() {
      this.$emit("obterMaisConteudo");
    },

    closeOnOutsideClick(event) {
      const isClickInsideComponent = this.$el.contains(event.target);
      if (!isClickInsideComponent && this.showSelected === true) {
        this.__closeSelected();
      }
    },
    executeSearch() {
      console.log("Search in component", this.pesquisa);
      this.$emit("executeSearch", this.pesquisa);
    },
  },
};
</script>
<style scoped>
.wrapper {
  z-index: 8;
  max-height: 4vw;
  width: 9.8vw;
  position: relative;
  overflow: hidden;
}

.abrir-style {
  cursor: not-allowed;
}

.select-btn,
.options li {
  display: flex;
  cursor: pointer;
  align-items: center;
  white-space: nowrap;
}
.tooltip {
  position: fixed;
  background-color: #5f6368;
  color: white;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  pointer-events: none;
  top: 10px;
}


.select-btn.disabled {
  cursor: not-allowed;
}

.select-btn {
  position: relative;
  width: 9.5vw;
  height: 2rem;
  font-size: 16px;
  display: flex;
  padding: 0 10px;
  border: 1px solid #929090;
  border-radius: 7px;
  cursor: pointer;
  color: #929090;
  background: #fff;
  align-items: center;
  justify-content: space-between;
}

.button-expediente {
  width: 15vw;
  height: 5vh;
  color: white;
  background-color: #9290909d;
  border: none;
  cursor: pointer;
}

.select-btn span {
  max-width: 9.5vw;
  overflow: hidden;
}

.fa-angle-down.rotated {
  transform: rotate(-180deg);
  transition: transform 0.5s linear;
}

.content {
  margin-top: 10px;
  padding: 5px;
  background: #fff;
  border: 1px solid #929090;
  color: #929090;
  border-radius: 7px;
  position: fixed;
  max-width: 9.7vw;
}

.search input {
  height: 30px;
  width: 100%;
  outline: none;
  font-size: 16px;
  border-radius: 5px;
  padding: 0 15px 0;
  border: 1px solid #929090;
}

.content .options {
  margin-top: 10px;
  max-height: 30vh;
  overflow-y: auto;
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.options::-webkit-scrollbar {
  width: 7px;
}

.options::-webkit-scrollbar-track {
  background: #f2f2f2;
  border-radius: 25px;
}

.options::-webkit-scrollbar-thumb {
  background: #dcdcdc;
  border-radius: 25px;
  border: none;
}

.options li {
  height: 50px;
  padding: 0 13px;
  font-size: 16px;
  border-radius: 5px;
}

.options li:hover {
  background: #eeeded;
}
#button-add:hover .DicaTexto {
  visibility: visible;
}
#button-load {
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: green;
}
#button-load:hover .DicaTexto {
  visibility: visible;
}

.selected-background {
  background: #eeeded;
  /* ou qualquer cor desejada */
}

#button-add:hover .DicaTexto {
  visibility: visible;
}

#button-load {
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: green;
}

#button-load:hover .DicaTexto {
  visibility: visible;
}

.DicaTexto {
  position: absolute;
  visibility: hidden;
  font-size: 13px;
  width: 100px;
  background: rgba(37, 37, 37, 0.695);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 4px;
  bottom: 100%;
  left: 50%;
  margin-left: -50px;
  margin-bottom: 5px;
  transition-timing-function: ease;
  transition-duration: 0.2s;
}

.DicaTexto:after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(37, 37, 37, 0.695) transparent transparent transparent;
}

/* .rotated {
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;
} */

.rotating {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>