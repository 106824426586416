<script>
//import this.$axios.get from 'this.$axios.get'
import { AlertDataSend } from "../alert";
import { numeric } from "@vuelidate/validators";
const url = process.env.VUE_APP_API_URL;

export default {
  name: "ExpedienteSeletor",

  props: {
    idEmpresa: String,
    idExpediente: numeric,
  },
  data() {
    return {
      listaDeDias: [
        "Domingo",
        "Segunda",
        "Terça",
        "Quarta",
        "Quinta",
        "Sexta",
        "Sábado",
      ],

      diaSemana: [],
      listaExpediente: [
        {
          dia_da_semana: 0,
          primeiraEntrada: null,
          primeiraSaida: null,
          segundaEntrada: null,
          segundaSaida: null,
        },
        {
          dia_da_semana: 1,
          primeiraEntrada: null,
          primeiraSaida: null,
          segundaEntrada: null,
          segundaSaida: null,
        },
        {
          dia_da_semana: 2,
          primeiraEntrada: null,
          primeiraSaida: null,
          segundaEntrada: null,
          segundaSaida: null,
        },
        {
          dia_da_semana: 3,
          primeiraEntrada: null,
          primeiraSaida: null,
          segundaEntrada: null,
          segundaSaida: null,
        },
        {
          dia_da_semana: 4,
          primeiraEntrada: null,
          primeiraSaida: null,
          segundaEntrada: null,
          segundaSaida: null,
        },
        {
          dia_da_semana: 5,
          primeiraEntrada: null,
          primeiraSaida: null,
          segundaEntrada: null,
          segundaSaida: null,
        },
        {
          dia_da_semana: 6,
          primeiraEntrada: null,
          primeiraSaida: null,
          segundaEntrada: null,
          segundaSaida: null,
        },
      ],
      primeiraEntrada: "08:00",
      primeiraSaida: "12:00",
      segundaEntrada: "13:00",
      segundaSaida: "17:00",
      nomeExpediente: "",
      opcaoSelecionada: "todos",
      isModalOpen: false,
    };
  },
  created() {
    if (this.idExpediente) {
      this.$axios
        .get(url + "/expediente/horas", {
          params: { id: this.idExpediente },
        })
        .then((response) => {
          this.nomeExpediente = response.data.nome;
          this.listaExpediente = response.data.horario_expediente;
        })
        .catch((error) => {
          console.error('Erro ao fazer requisição:', error);
        });
    }
    document.addEventListener("keydown", this.handleEscKey);
    document.addEventListener("click", this.handleClickOutside);
    this.filtrarDiasUteis();
    this.isModalOpen = true;
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
    document.removeEventListener("keydown", this.handleEscKey);
  },
  methods: {
    enviarExpedienteparaBanco() {
      if (this.nomeExpediente.trim() === "") {
        AlertDataSend("Por favor, preencha o campo Nome do Expediente.");
        return;
      }
      const primeiraEntradaPreenchida = this.listaExpediente.find(
        (item) => item.primeiraEntrada !== null
      );
      if (!primeiraEntradaPreenchida) {
        AlertDataSend("Por favor, preencha os horários.");
        return;
      }
      if (this.idExpediente) {
        this.atualizarExpediente();
      } else {
        this.$axios
          .post(url + "/expediente", {
            nomeExpediente: this.nomeExpediente,
            expediente: this.listaExpediente,
            idEmpresa: this.idEmpresa,
          })
          .then((response) => {
            this.fecharPopup();
            location.reload();
            return response.data;
          });
      }
    },
    atualizarExpediente() {
      this.$axios
        .put(url + "/expediente/horas", {
          idExpediente: this.idExpediente,
          nomeExpediente: this.nomeExpediente,
          expediente: this.listaExpediente,
        })
        .then((response) => {
          this.fecharPopup();
          location.reload();
        });
    },
    handleEscKey(event) {
      if (event.key === "Escape") {
        this.fecharPopup();
      }
    },
    handleClickOutside() {
      if (!this.isModalOpen) {
        this.fecharPopup();
      }
      this.isModalOpen = false;
    },

    //estar bugado
    fecharPopup() {
      this.$emit("fecharPopUp");
      //this.$router.replace({ name: 'telaExpediente', query: {} });
    },
    removerExpediente(index) {
      if (index !== undefined) {
        const expediente = this.listaExpediente[index];
        expediente.primeiraEntrada = null;
        expediente.primeiraSaida = null;
        expediente.segundaEntrada = null;
        expediente.segundaSaida = null;
      } else {
        this.listaExpediente.forEach((expediente) => {
          expediente.primeiraEntrada = null;
          expediente.primeiraSaida = null;
          expediente.segundaEntrada = null;
          expediente.segundaSaida = null;
        });
      }
    },
    removerTodosExpediente() {
      this.listaExpediente.forEach((expediente) => {
        expediente.primeiraEntrada = null;
        expediente.primeiraSaida = null;
        expediente.segundaEntrada = null;
        expediente.segundaSaida = null;
      });
    },
    filtrarDiasUteis() {
      if (this.opcaoSelecionada === "seg.a.Sex") {
        const finalDeSemana = [0, 6];
        for (let data of finalDeSemana) {
          const expediente = this.listaExpediente[data];
          expediente.primeiraEntrada = null;
          expediente.primeiraSaida = null;
          expediente.segundaEntrada = null;
          expediente.segundaSaida = null;
        }
      }
    },
    atualizarHorarios() {
      if (this.opcaoSelecionada === "seg.a.Sex") {
        this.listaExpediente.forEach((expediente) => {
          expediente.primeiraEntrada = this.primeiraEntrada;
          expediente.primeiraSaida = this.primeiraSaida;
          expediente.segundaEntrada = this.segundaEntrada;
          expediente.segundaSaida = this.segundaSaida;
        });
        this.filtrarDiasUteis();
      } else {
        this.listaExpediente.forEach((expediente) => {
          expediente.primeiraEntrada = this.primeiraEntrada;
          expediente.primeiraSaida = this.primeiraSaida;
          expediente.segundaEntrada = this.segundaEntrada;
          expediente.segundaSaida = this.segundaSaida;
        });
      }
    },
  },
};
</script>

<template>
  <main class="conteudo">
    <div class="popup" ref="modalContent" @click.stop>
      <section class="botoes">
        <span style="font-size: 1.6rem; font-weight: bolder">Cadastrar Expediente</span>
        <span @click="fecharPopup" style="cursor: pointer; color: red; font-size: 1.5rem"><i
            class="fa-solid fa-xmark"></i></span>
      </section>
      <div>
        <span style="color: green">
          <input type="text" class="input-expediente" placeholder="Nome do expediente" v-model="nomeExpediente" />
        </span>
      </div>
      <table cellspacing="0">
        <thead>
          <td class="day-style">Dia da Semana</td>
          <td class="day-style">1° entrada</td>
          <td class="day-style">1° saída</td>
          <td class="day-style">2° entrada</td>
          <td class="day-style">2° saída</td>
          <td class="day-style"></td>
        </thead>
        <tbody>
          <td class="day-style">
            <select class="style-select" v-model="opcaoSelecionada" @change="filtrarDiasUteis">
              <option value="todos" selected>Todos</option>
              <option value="seg.a.Sex">Seg.a Sex.</option>
            </select>
            <!-- <label class="label_checkbox">Alterar todos os Horarios</label> -->
          </td>
          <td class="day-style">
            <input class="time" type="time" v-model="primeiraEntrada" />
          </td>
          <td class="day-style">
            <input class="time" type="time" v-model="primeiraSaida" />
          </td>
          <td class="day-style">
            <input class="time" type="time" v-model="segundaEntrada" />
          </td>
          <td class="day-style">
            <input class="time" type="time" v-model="segundaSaida" />
          </td>
          <td class="day-style">
            <div class="style-adc">
              <button style="border: transparent; background-color: transparent" id="button-add"
                v-if="!temItensSelecionados" @click="atualizarHorarios()">
                <p id="icon-add">+</p>
              </button>
              <button id="button-excluir" v-if="temItensSelecionados" @click="excluirSelecionados">
                <i class="fa-solid fa-trash"></i>
              </button>
            </div>
          </td>
          <!-- <td class="day-style">
          <span @click="atualizarHorarios()" style="cursor: pointer; color: green"><i
              class="fa-solid fa-plus"></i></span>
        </td> -->
        </tbody>
        <tbody v-for="(expediente, index) of listaExpediente" :key="index">
          <td class="dia">
            <label class="label_checkbox">
              {{ listaDeDias[index] }}
            </label>
          </td>
          <td>
            <input class="time" type="time" v-model="expediente.primeiraEntrada" />
          </td>
          <td>
            <input class="time" type="time" v-model="expediente.primeiraSaida" />
          </td>
          <td>
            <input class="time" type="time" v-model="expediente.segundaEntrada" />
          </td>
          <td>
            <input class="time" type="time" v-model="expediente.segundaSaida" />
          </td>
          <td>
            <span @click="removerExpediente(index)" style="cursor: pointer; color: red; margin-left: 9px;">
              <i class="fa-solid fa-xmark"></i>
            </span>
          </td>
        </tbody>
      </table>
      <section class="botoes">
        <div class="botao_popup" @click="removerTodosExpediente()" style="background: rgb(255, 108, 108)">
          Limpar
        </div>
        <div class="botao_popup" @click="enviarExpedienteparaBanco()" style="background: #2bb542">
          salvar
        </div>
      </section>
    </div>
  </main>
</template>

<style scoped>
.popup {
  width: 45vw;
  height: 77vh;
  background: white;
  font-size: 1rem;
  border-radius: 10px;
  padding: 2vw;
  display: flex;
  flex-direction: column;
  gap: 2vh;
  top: 6vw;
  left: 32vw;
  z-index: 1;
}

.style-adc {
  display: flex;
  background-color: transparent;
  width: 2vw;
}

#button-add {
  width: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #2bb542;
  font-size: 1.2vw;
}

#icon-add {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: transparent;
  font-size: 1.6rem;
  border-radius: 7px;
  border: transparent;
  height: 90%;
  width: 1.6vw;
  background-color: #2bb542;
  color: white;
}

.botao_popup {
  width: 10vw;
  border: none;
  height: 35px;
  font-size: 1.2rem;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.conteudo {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Cor de fundo semitransparente */
  font-family: Arial, Helvetica, sans-serif;
}

.input-expediente {
  height: 3.8vh;
  width: 40.3vw;
  border-radius: 5px;
  border: 2px solid rgba(185, 184, 184, 0.84);
  margin-left: 5px;
}

.style-select {
  height: 3.2vh;
  width: 8vw;
  border-radius: 5px;
  border: 1px solid rgba(185, 184, 184, 0.747);
}

.time {
  border: 1px solid rgba(185, 184, 184, 0.848);
  border-radius: 5px;
  height: 25px;
}

.botoes .botao_popup {
  border: none;
  width: 8vw;
  color: white;
}

.botoes {
  display: flex;
  width: 40vw;
  justify-content: space-between;
}

table {
  flex-wrap: wrap;
}

.day-style {
  color: green;
}

td {
  padding: 1vh;
  border-bottom: 1px solid grey;
}
</style>