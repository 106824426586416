import axios from 'axios'
export async function checkToken() {
  try {
    const url = process.env.VUE_APP_API_URL
    const { data } = await axios.get(url + '/login/verify', {
      withCredentials: true
    })
    return data
  } catch (error) {
    const url = process.env.VUE_APP_API_URL
     await axios.post(url + "/logout", { withCredentials: true });
    return false;
  }
}