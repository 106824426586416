<script>
export default {
  props: { filtros: [] },
  data() {
    return {
      pagesDropdownOpen: false,
      dropdowns: {},
      filtrosFiltradosPainel: [],
      filtrosFiltradosPasta: [],
      filtrosFiltradosObjeto: [],
    };
  },
  watch: {
    filtros: {
      handler(novosFiltros) {
        this.filtrarFiltros();
      },
    },
  },
  created() {
    window.addEventListener("click", this.closeOnOutsideClick);
    this.filtrarFiltros();
  },
  beforeDestroy() {
    window.removeEventListener("click", this.closeOnOutsideClick);
  },
  methods: {
    togglePagesDropdown() {
      this.pagesDropdownOpen = !this.pagesDropdownOpen;
    },
    toggleDropdown(dropdown) {
      Object.keys(this.dropdowns).forEach((key) => {
        if (key !== dropdown) {
          this.dropdowns[key] = false;
        }
      });
      this.dropdowns[dropdown] = !this.dropdowns[dropdown];
    },
    handleDataChosen(data) {
      this.$emit("selectedOption", data);
    },
    filtrarFiltros() {
      this.filtrosFiltradosPainel = this.filtrarPorTipo("painel");
      this.filtrosFiltradosPasta = this.filtrarPorTipo("sheet");
      this.filtrosFiltradosObjeto = this.filtrarPorTipo("objeto");
    },
    filtrarPorTipo(tipo) {
      return this.filtros.filter((filtro, index) => {
        return (
          this.filtros.findIndex(
            (item) => item[tipo] && item[tipo].title === filtro[tipo].title
          ) === index
        );
      });
    },
    closeOnOutsideClick(event) {
      if (!this.$el.contains(event.target)) {
        Object.keys(this.dropdowns).forEach((key) => {
          this.dropdowns[key] = false;
        });
      }
    },
  },
};
</script>
<template>
  <body>
    <div class="menu-bar">
      <ul>
        <li class="all-style" @click="togglePagesDropdown">
          <div class="button-onclik">
            <i class="fa-solid fa-caret-down"></i>
          </div>
          <div v-if="pagesDropdownOpen" class="dropdown-menu">
            <ul>
              <li class="style-tst">
                <a @click.stop="toggleDropdown('painel')">
                  <i id="icons" class="fa-solid fa-magnifying-glass"></i>Painel
                  <i id="icon-open" class="fas fa-caret-right"></i>
                </a>
                <div v-if="dropdowns.painel" class="dropdown-menu-1">
                  <ul>
                    <li
                      v-for="(filtro, index) in filtrosFiltradosPainel"
                      :key="index"
                    >
                      <p
                        @click="
                          handleDataChosen({ painel: filtro.painel.title })
                        "
                      >
                        {{ filtro.painel.title }}
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="style-tst">
                <a @click.stop="toggleDropdown('pasta')"
                  ><i id="icons" class="fa-solid fa-magnifying-glass"></i>Pasta
                  <i id="icon-openn" class="fas fa-caret-right"></i
                ></a>
                <div v-if="dropdowns.pasta" class="dropdown-menu-1">
                  <ul>
                    <li
                      v-for="(filtro, index) in filtrosFiltradosPasta"
                      :key="index"
                    >
                      <p
                        @click="handleDataChosen({ pasta: filtro.sheet.title })"
                      >
                        {{ filtro.sheet.title }}
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="style-tst">
                <a @click.stop="toggleDropdown('objeto')">
                  <i id="icons" class="fa-solid fa-magnifying-glass"></i>Objeto
                  <i id="icon-open" class="fas fa-caret-right"></i
                ></a>
                <div v-if="dropdowns.objeto" class="dropdown-menu-1">
                  <ul>
                    <li
                      v-for="(filtro, index) in filtrosFiltradosObjeto"
                      :key="index"
                    >
                      <p
                        @click="
                          handleDataChosen({ objeto: filtro.objeto.title })
                        "
                      >
                        {{ filtro.objeto.title }}
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </body>
</template>
  
  <style scoped>
:root {
  --color-primary: #0073ff;
  --color-whitee: #100f0f;
  --color-black: #141d28;
  --color-black-1: #212b38;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.style-tst {
  height: 30px;
  width: 150px;
  padding-top: 3px;
}
body {
  font-family: sans-serif;
}
p {
  font-size: 17px;
}
#icons {
  color: #545556;
  font-size: 15px;
  margin-right: 5px;
}
#icon-open {
  font-size: 15px;
  margin-left: 45px;
  margin-right: 8px;
}
#icon-openn {
  font-size: 15px;
  margin-left: 50px;
  margin-right: 8px;
}
.button-onclik {
  color: black;
  font-size: 0.8rem;
}
.menu-bar {
  background-color: var(--color-black);
  height: px;
  width: 100%;
}

.menu-bar ul {
  list-style: none;
  display: block;
  margin: 2px;
}
.all-style {
  border: none;
}
ul li {
  border: 1px solid rgba(185, 184, 184, 0.747);
}
.menu-bar ul li {
  color: black;
  cursor: pointer;
  background-color: white;
  font-size: 18px;
}

.menu-bar ul li a {
  font-size: 18px;
  color: var(--color-whitee);
  text-decoration: none;
  transition: all 0.3s;
}

.menu-bar ul li a:hover {
  color: var(--color-primary);
}

.fas {
  float: right;
  margin-left: 10px;
  padding-top: 3px;
}

/* dropdown menu style */
.dropdown-menu {
  position: absolute;
  z-index: 9;
  width: 150px;
  height: 100px;
  padding: 5px;
  margin: 2px;
}

.menu-bar ul li:hover .dropdown-menu {
  display: block;
  position: absolute;
  /* left: 0; */
  /* top: 100%; */
  background-color: var(--color-black);
}

.menu-bar ul li:hover .dropdown-menu ul {
  display: block;
  /* margin: 2px;  */
}

.menu-bar ul li:hover .dropdown-menu ul li {
  width: 150px;
  /* padding: 5px;  */
}

.dropdown-menu-1 {
  display: none;
}

.dropdown-menu ul li:hover .dropdown-menu-1 {
  display: block;
  position: absolute;
  left: 152px;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-black);
  /* font-size: 16px;  */
}
</style>