import axios from 'axios';
const createAxiosInstance = (url) => {
  const instance = axios.create({
    baseURL: url,
    timeout: 1000 * 60, 
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  });
  instance.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  return instance;
};
export default createAxiosInstance;
