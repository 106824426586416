<script>
import io, { Socket } from "socket.io-client";
//import this.$axios.get from "this.$axios.get";
import qrCode from "../components/configuracao/qrCode";
import formConfig from "../components/configuracao/formConfig";
import { AlertDataSend } from "../components/alert";

const url = process.env.VUE_APP_API_URL;
const urlSocket = process.env.VUE_APP_API_URL_SOCKET;
const ulrConnect = process.env.VUE_APP_CONNECT_SOCKET;
export default {
  components: { qrCode, formConfig },

  data() {
    return {
      lista_component: [],
      dados: null,
      tenantPrefix: "https://",
      cloudDomain: ".us.qlikcloud.com",
      model: "",
      tenantInput: "",
      sessao: "",
      appkey: "",
      tenant: "",
      qrCode: null,
      alt: "QRCode",
      state: "QRCode",
      status_conexao: "",
      enviarDados: null,
      dadosSalvos: false,
      credentials: Object,
      socket: null,
      loading: false,
      toRemoveShowCountdown: false,
    };
  },
  watch: {
    qrCode(newValue) {
      if (newValue === "") {
        this.status_conexao = "";
      }
    },
  },
  unmounted() {
    if (this.socket) {
      this.socket.disconnect();
    }
  },
  async created() {
    this.rendelizacaoDadosF();
    await this.initializeTheSocket();
    this.lidarComSocket();
  },
  methods: {
    async initializeTheSocket() {
      this.socket = io(ulrConnect);
    },
    message(text, tipo) {
      AlertDataSend(text, tipo);
    },
    sessaoConectada() {
      this.status_conexao = "conectado";
    },
    async enviarConexao() {
      if (!this.sessao) {
        this.handleSessionIsNull();
        return;
      }
      const dadosEmpresa = this.pegarDadosNoComponenteFilho();
      if (
        dadosEmpresa.sessao !== "" &&
        dadosEmpresa.appkey !== "" &&
        dadosEmpresa.tenant !== ""
      ) {
        this.status_conexao = "carregando";
        this.sessao = dadosEmpresa.sessao;

        await this.salvarDadosConexao(dadosEmpresa, true);

        this.iniciarConexao();
      }
    },
    emitSessao(payload) {
      if (payload && payload !== "") {
        this.$axios
          .post(urlSocket + "/session", { session: payload })
          .then((response) => {
            if (response.data) {
              this.status_conexao = "conectado";
            }
          })
          .catch((error) => AlertDataSend("Sessão do WhatsApp não conectada."));
      }
    },
    removerConexao() {
      this.status_conexao = "carregando";
      this.$axios
        .delete(urlSocket + "/session/logout", {
          data: { session: this.sessao },
        })
        .then(() => {
          this.status_conexao = "";
          this.qrCode = "";
        })
        .catch((error) => {
          AlertDataSend("Não foi possível desconectar sua sessão.");
        });
    },
    failedConnection() {
      if (this.qrCode === null && this.status_conexao === "carregando") {
        this.status_conexao = "";
        this.qrCode = null;
      }
    },
    iniciarConexao() {
      this.socket.emit("connectSession", this.sessao);
      this.state = "gerando qr code";
      this.carregado = true;
    },
    async handleSessionIsNull() {
      this.message("Por favor insira as informações referente ao qlik.");
      this.status_conexao = "";
      this.qrCode = "";
      await this.$refs.qrCode.toRemoveShowCountdown();
    },

    async salvarDadosConexao(payload, qrCode) {
      const localStorageObject = JSON.parse(localStorage.getItem("User"));
      const idUser = localStorageObject.Id;
      const nomeEmpresa = localStorageObject.User;

      return this.$axios
        .post(url + "/empresa/conexao", {
          idEmpresa: idUser,
          nomeEmpresa: nomeEmpresa,
          sessao: payload.sessao,
          appkey: payload.appkey,
          tenant: payload.tenant,
        })
        .then((response) => {
          if (!qrCode) {
            this.sessao = response.data;
            this.message("Informações salvas com sucesso", "alert");
            return;
          } else {
            this.sessao = response.data;
            return response.data;
          }
        })
        .catch(() => this.message("Não foi possivel salvar os dados!"));
    },
    async rendelizacaoDadosF() {
      this.loading = true;
      const localStorageObject = await JSON.parse(localStorage.getItem("User"));
      const idEmpresa = localStorageObject.Id;
      this.$axios
        .get(url + "/empresa/conexao", {
          params: { id: idEmpresa },
        })
        .then((response) => {
          this.sessao = response.data.sessao;
          this.tenant = response.data.tenant;
          this.appkey = response.data.appkey;
          this.credentials = response.data;
          this.emitSessao(this.sessao);
          this.loading = false;
        })
        .catch((error) => {
          console.error("Erro na solicitação:", error);
        });
    },
    // gerenciamento img qrcode
    lidarComSocket() {
      this.socket.on("enviar-imagem", (imageData) => {
        this.qrCode = "data:image/png;base64," + imageData;
        this.status_conexao = "carregado";
        this.state = "aponte a câmera";
        this.$refs.qrCode.mudancaStatus();
        this.socket.on("estado-de-conexao", (state) => {
          if (state) {
            this.status_conexao = "conectado";
            this.state = "conectado";
          } else {
            this.status_conexao = "error";
            this.state = "A conexão falhou tente novamente";
          }
          this.qrCode = "check";
          this.state = "conectado";
        });
      });
    },

    salvarDadosConexaoParaDesconectar(payload) {
      this.lista_component = payload;
      this.removerConexao();
    },
    pegarDadosNoComponenteFilho() {
      const dadosEmpresa = this.$refs.formConfig.getData();
      return dadosEmpresa;
    },
  },
};
</script>

<template>
  <main>
    <header class="base">
      <div class="caixaTitulo">
        <div class="caixaTitulo_titulo">Conexão</div>
      </div>
      <div class="conteudo">
        <div class="form" id="elemento_form">
          <formConfig
            ref="formConfig"
            @salvarDadosConexao="salvarDadosConexao"
            :mudar_estado="mudar_estado"
            :enviarDados="enviarDados"
            @enviarConexao="enviarConexao"
            @emitSessao="emitSessao"
            @salvarDadosConexaoParaDesconectar="
              salvarDadosConexaoParaDesconectar
            "
            @sessaoConectada="sessaoConectada"
            :status_conexao="status_conexao"
            :credentials="credentials"
          ></formConfig>
        </div>
        <div class="qrCode" id="elemento_imagem">
          <qrCode
            ref="qrCode"
            :status_conexao="status_conexao"
            :state="state"
            :qrCode="qrCode"
            @enviarConexao="enviarConexao"
            @removerConexao="removerConexao"
            @failedConnection="failedConnection"
          >
          </qrCode>
        </div>
        <div class="overlay" v-if="loading">
          <i id="icon-style" class="fa-solid fa-gear"></i>
          <i id="icon-stylee" class="fa-solid fa-gear"></i>
        </div>
      </div>
    </header>
  </main>
</template>

<style scoped>
.conteudo {
  width: 100%;
  display: flex;
  box-shadow: none;
  justify-content: space-between;
  max-width: 100%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.overlay .fa-gear {
  animation: spin 1s linear infinite;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

#icon-style {
  align-items: center;
  font-size: 3rem;
  color: white;
}

#icon-stylee {
  font-size: 2rem;
  color: white;
  align-items: end;
}

.caixaTitulo_titulo {
  height: 1.7vw;
  margin-left: 1px;
}

.form {
  box-shadow: 1px 1px 2px 2px rgb(217, 217, 217);
  border-radius: 5px;
  padding: 38px;
}

.qrCode {
  box-shadow: 1px 1px 2px 2px rgb(217, 217, 217);
  border-radius: 5px;
  padding: 38px;
  margin-right: 15em;
}

.style-score {
  font-size: 1.2rem;
  margin-left: 6vw;
}
</style>
