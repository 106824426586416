<script>
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, maxLength, minValue } from "@vuelidate/validators";
import { mask } from "vue-the-mask";
import { AlertDataSend } from "../components/alert";
import AlterarSenha from "./AlterarSenha.vue";
import { limpaMascara } from "../components/cadastroUsuario/util.js";

const url = process.env.VUE_APP_API_URL;

export default {
  name: "EditarEmpresa",
  components: { AlterarSenha },
  setup() {
    return { v$: useVuelidate() };
  },
  directives: { mask },

  data() {
    return {
      nome: null,
      senha: "",
      imagemTratada: "",
      mostrarSenha: false,
      modoEdicaoSenha: false,
      mostrarIconeNome: false,
      mostrarIconeQuantidade: false,
      quantidadeUruario: null,
      exibirQuantidadeUsuario: true,
      numeroBot: null,
      numeroWpp: null,
      mostrarModalAlterarSenha: false,
      mostrarModalEditarEmpresa: true,
    };
  },
  props: {
    idEmpresa: String,
    nomePreenchido: Boolean,
    usuarioPreenchido: Boolean,
    empresasConectadasDados: Array,
  },

  validations() {
    return {
      nome: { required, maxLength: maxLength(30) },
      senha: { required, minLengthRef: minLength(8) },
      quantidadeUruario: { required, minValue: minValue(1) },
      numeroWpp: { required, minLengthRef: minLength(15) },
    };
  },

  async created() {
    if (this.idEmpresa) {
      await this.$axios
        .get(url + "/empresa", {
          params: { id: this.idEmpresa },
        })
        .then((response) => {
          console.log("dados empresa", response);
          if (response.data) {
            this.nome = response.data.nome;
            this.senha = response.data.password;
            this.quantidadeUruario = response.data.qtd_usuario;
            this.numeroWpp = response.data.contato_admin;
            this.numeroBot = response.data.numero_bot;
          } else {
            console.error("Dados da empresa não encontrados");
          }
        })
        .catch((error) => {
          console.error("Erro ao fazer requisição:", error);
        });
    }
    document.addEventListener("keydown", this.handleEscKey);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleEscKey);
  },

  methods: {
    async gerarSenha() {
      const senha = await this.$axios.get(url + "/gerarsenha");
      this.senha = senha.data;
    },
    abrirModalAlterarSenha(event) {
      event.preventDefault();
      this.mostrarModalEditarEmpresa = false;
      this.mostrarModalAlterarSenha = true;
    },
    fecharModalAlterarSenha() {
      this.mostrarModalAlterarSenha = false;
      location.reload();
    },
    mudarEstadoSenha() {
      this.mostrarSenha = !this.mostrarSenha;
    },
    handleEscKey(event) {
      if (event.key === "Escape") {
        this.$emit("fecharCadastroEmpresa");
      }
    },
    definirModoEdicaoSenha() {
      this.modoEdicaoSenha = true;
    },
    async confirma_envio() {
      this.v$.$validate();

      if (!this.numeroWpp) {
        AlertDataSend("O número do administrador é obrigatório.");
        return;
      }

      const empresa = this.empresasConectadasDados?.find(
        (emp) => emp.id_empresa === this.idEmpresa
      );
      if (!empresa) {
        if (this.v$.$error || this.v$.quantidadeUruario.$error) {
          AlertDataSend("Preencha os campos corretamente");
        } else {
          await this.enviarEmpresa();
          this.$emit("fecharCadastroEmpresa");
        }
        return;
      }

      if (empresa.qtd_usuarios_cadastrados > this.quantidadeUruario) {
        AlertDataSend("Não foi possível atualizar");
        return;
      }
      if (this.v$.$error || this.v$.quantidadeUruario.$error) {
        AlertDataSend("Preencha os campos corretamente");
      } else {
        await this.enviarEmpresa();
        this.$emit("fecharCadastroEmpresa");
      }
    },
    ocultarQuantidadeUsuarios() {
      this.exibirQuantidadeUsuario = false;
    },

    esconderIcone(campo) {
      if (campo === "nome") {
        this.mostrarIconeNome = false;
      } else if (campo === "cnpj") {
        this.mostrarIconeCnpj = false;
      }
    },
    async enviarEmpresa() {
      try {
        const dados = {
          idEmpresa: this.idEmpresa,
          nome: this.nome,
          senha: this.senha,
          quantidadeUruario: this.quantidadeUruario,
          contato_admin: limpaMascara(this.numeroWpp),
        };
        if (this.idEmpresa) {
          if (this.nome) {
            await this.$axios
              .put(url + "/empresa", dados)
              .then(async (response) => {})
              .catch((error) => {
                AlertDataSend("Não foi possível adicionar empresa.");
              });
          }
        } else {
          if (this.nome) {
            await this.$axios
              .post(url + "/empresa", dados)
              .then(async (response) => {
                location.reload();
              })
              .catch((error) => {
                AlertDataSend("Não foi possível adicionar empresa.");
              });
          }
        }
      } catch (erro) {
        AlertDataSend("Erro ao cadastrar empresa.");
      }
    },
  },
};
</script>

<template>
  <main class="conteudo">
    <section v-if="mostrarModalEditarEmpresa">
      <div class="caixaTitulo">
        <div class="caixaTitulo_titulo">
          <span>Editar empresa</span>
          <span @click="$emit('fecharCadastroEmpresa')" class="btn_sair">
            <i
              style="color: red; font-size: 1.7rem"
              class="fa-solid fa-xmark"
            ></i>
          </span>
        </div>
      </div>
      <div class="formConteiner">
        <form class="item" id="elemento_form">
          <label class="titulo_form"> </label>
          <div class="caixa_input">
            <label for="nome">Login</label>
            <div class="input_icon">
              <input
                :class="[{ error: v$.nome.$error }]"
                type="text"
                id="nome"
                placeholder="Digite o login"
                autocomplete="off"
                class="input_padrao"
                maxlength="30"
                v-model="v$.nome.$model"
                :readonly="nomePreenchido"
              />
            </div>
            <p class="input_error" v-if="v$.nome.$error">Campo obrigatório</p>
          </div>
          <div class="caixa_input">
            <label for="nome">Número do administrador (WhatsApp)</label>
            <div class="input_icon">
              <input
                v-model="numeroWpp"
                :class="[{ error: v$.numeroWpp.$error }]"
                type="tel"
                id="numero"
                v-mask="'55(##)####-#####'"
                placeholder=" 🇧🇷 (DDD) 0000-0000"
                autocomplete="off"
                class="input_padrao"
              />
            </div>
            <p class="input_error" v-if="v$.numeroWpp.$error">
              Campo obrigatório
            </p>
          </div>
          <div class="caixa_input">
            <div class="senha-usuario-style">
              <div class="usuario-style" v-if="exibirQuantidadeUsuario">
                <label for="usuarios">Usuários</label>
                <input
                  :class="[{ erro: v$.quantidadeUruario.$error }]"
                  type="number"
                  class="input_padrao"
                  id="usuarios"
                  v-model="v$.quantidadeUruario.$model"
                  :readonly="usuarioPreenchido"
                />
                <p class="input_error" v-if="v$.quantidadeUruario.$error">
                  Informe um valor (mínimo: 1).
                </p>
              </div>
            </div>
            <div>
              <button
                class="button-alterar"
                type="button"
                @click="abrirModalAlterarSenha"
              >
                Alterar Senha
                <p class="traco"></p>
              </button>
            </div>
          </div>
          <div class="caixa_botao">
            <div
              :class="[{ validado: !v$.$invalid }]"
              class="botao_padrao"
              type="submit"
              value="Salvar"
              @click="confirma_envio"
            >
              <p>Enviar</p>
            </div>
          </div>
        </form>
      </div>
    </section>
    <AlterarSenha
      v-if="mostrarModalAlterarSenha"
      @fecharCadastroEmpresa="fecharModalAlterarSenha"
      :idEmpresa="idEmpresa"
    />
  </main>
</template>
  
<style scoped>
.button-alterar {
  border: none;
  color: #b3b3b3;
  background-color: transparent;
  cursor: pointer;
  font-size: 1rem;
  padding: 15px 5px;
}
.traco {
  display: flex;
  width: 100%;
  border: 1px solid #b3b3b3;
}
.input_padrao {
  color: black;
}
.usuario-style {
  width: 23vw;
}
.senha-usuario-style {
  float: left;
  display: flex;
  justify-content: space-between;
}
.caixaTitulo_titulo {
  display: flex;
  font-size: 1.5rem;
  justify-content: space-between;
  width: 100%;
}
#usuarios {
  width: 100%;
}
.btn_sair {
  cursor: pointer;
}

.formConteiner {
  display: flex;
  justify-content: space-evenly;
  height: 100%;
}

.caixaTitulo {
  width: 100%;
}

section {
  border-radius: 10px;
  display: flex;
  position: absolute;
  padding: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  justify-content: space-around;
  flex-direction: column;
}

.conteudo {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Cor de fundo semitransparente */
  font-family: Arial, Helvetica, sans-serif;
}

.conteudo .item {
  height: 100%;
}

.caixa_input {
  width: 100%;
}

.espaco_imagem {
  height: 60%;
  aspect-ratio: 1/1;
}

#elemento_form,
#elemento_imagem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

#senha {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.verSenha {
  display: flex;
  align-items: center;
  width: 87%;
}

.verSenha button {
  width: 15%;
  background: transparent;
  color: black;
  border: 1px solid #b3b3b3;
  height: 40px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1rem;
}

.state {
  cursor: pointer;
}

#input-img {
  display: none;
}

.input-imagen {
  text-align: center;
  border: 1px solid #b3b3b3;
  padding: 15px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
}

#textoobservacao {
  font-size: 13px;
  color: #b3b3b3;
}

#geraSenha {
  background-color: white;
  height: 30px;
  border-radius: 5px;
  font-weight: 100;
  cursor: pointer;
}

.botao_padrao {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 38px;
  background-color: #2bb542;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 2px;
}

.caixa_botao {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
</style>
