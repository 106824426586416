<script>
//import this.$axios.get from "this.$axios.get";
import ConfirmationModalVue from "../components/Comfirmacao/ConfirmationModal.vue";
const url = process.env.VUE_APP_API_URL;
import chatBotVue from "../components/Chatbot/chatBot.vue";
export default {
  components: {
    ConfirmationModalVue,
    chatBotVue,
  },
  data() {
    return {
      showHoursPopUp: false,
      idEmpresa: null,
      textForModal: "Deseja realmente excluir esta mensagem?",
      mensagemRenderizadas: [],
      estaOrdemAlfabetica: true,
      confirmarAoDeletar: false,
      dadosParaExcluir: [],
      filtrarChatbot: [],
      marcarTodos: null,
      loading: false,
      fixedData: [
        {
          id: 0,
          nameMsg: "Encerramento",
          msg: "😃 Agradecemos por escolher o Data Send. Estamos à disposição para atender qualquer nova solicitação que você possa ter!",
        },
        {
          id: 1,
          nameMsg: "Usuário desativado",
          msg: "⚠️ Seu usuário está desativado, por favor entre em contato com o administrador.",
        },
        {
          id: 2,
          nameMsg: "Cadastro não encontrado",
          msg: "⚠️ Não encontramos o seu cadastro. Por favor, entre em contato com o administrador.",
        },
        {
          id: 3,
          nameMsg: "Desconectado por inatividade",
          msg: "⏰ Você foi desconectado por inatividade.",
        },
        {
          id: 4,
          nameMsg: "Fora de expediente",
          msg: "⚠️ Solicitações fora do expediente não estão disponíveis.",
        },
      ],
      fixedDataChange: [
        {
          id: 0,
          nameMsg: "Encerramento",
          msg: "😃 Agradecemos por escolher o Data Send. Estamos à disposição para atender qualquer nova solicitação que você possa ter!",
          nothingInTheDatabase: true
        },
        {
          id: 1,
          nameMsg: "Usuário desativado",
          msg: "⚠️ Seu usuário está desativado, por favor entre em contato com o administrador.",
          nothingInTheDatabase: true
        },
        {
          id: 2,
          nameMsg: "Cadastro não encontrado",
          msg: "⚠️ Não encontramos o seu cadastro. Por favor, entre em contato com o administrador.",
          nothingInTheDatabase: true
        },
        {
          id: 3,
          nameMsg: "Desconectado por inatividade",
          msg: "⏰ Você foi desconectado por inatividade.",
          nothingInTheDatabase: true
        },
        {
          id: 4,
          nameMsg: "Fora de expediente",
          msg: "⚠️ Solicitações fora do expediente não estão disponíveis.",
          nothingInTheDatabase: true
        },
      ],
      fixedDataSend: null,
      exibirChatbot: false,
      mensagemPadrão: null,
    };
  },
  async created() {
    this.loading = true;
    const localStorageObject = JSON.parse(localStorage.getItem("User"));
    this.idEmpresa = localStorageObject.Id;
    if (this.idEmpresa) {
      this.$axios
        .get(url + "/message-pattern", {
          params: { idEmpresa: this.idEmpresa },
        })
        .then((response) => {
          for(let data of response.data){
            const index = this.fixedDataChange.findIndex((cod) => cod.id === data.messageCode)

            this.fixedDataChange[index].msg = data.message

            this.fixedDataChange[index].nothingInTheDatabase = false
          }
          this.filtrarChatbot = response.data;
          this.loading = false;
        })
        .catch(() =>  {
        this.loading = false
        } )
    }
  },
  methods: {
    deleteUserCanceled() {
      this.confirmarAoDeletar = false;
    },
    selecionartodos() {
      if (this.mensagemRenderizadas.length === this.filtrarChatbot.length) {
        this.mensagemRenderizadas = [];
      } else {
        this.mensagemRenderizadas = this.filtrarChatbot.map((dados) => dados.id);
      }
    },
    ordernarOrdemAlfabetica() {
      if (!this.ordenacaoAscendente) {
        this.filtrarChatbot.sort((a, b) => a.nome.localeCompare(b.nome));
        this.ordenacaoAscendente = true;
      } else {
        this.filtrarChatbot.sort((a, b) => b.nome.localeCompare(a.nome));
        this.ordenacaoAscendente = false;
      }
      this.estaOrdemAlfabetica = !this.estaOrdemAlfabetica;
    },
    openChatbot(row,index) {
      this.exibirChatbot = true;
      this.mensagemRenderizada = row
      this.mensagemPadrão = this.fixedData[index]

    },
    fechar() {
      this.exibirChatbot = false;
    },
    truncateText(text, length) {
      if (text.length <= length) {
        return text;
      }
      return text.substring(0, length) + '...';
    },
    voltarMsgPadrao(idMsg){
      const index = this.fixedDataChange.findIndex((cod) => cod.id === idMsg.id)

      this.fixedDataChange[index].msg = this.fixedData[index].msg
      this.fixedDataChange[index].nothingInTheDatabase = true
    },
    capturarMensagemPersonalizada(mensagemAlterada) {
      const index = this.fixedDataChange.findIndex((cod) => cod.id === mensagemAlterada.id)

      this.fixedDataChange[index].msg = mensagemAlterada.msg
      this.fixedDataChange[index].nothingInTheDatabase = false
    },
  },
};
</script>
<template  >
  <main class="base">
    <header class="caixaTitulo">
      <chat-bot-vue
        v-if="exibirChatbot"
        @fechar="fechar"
        @mensagemPersonalizadaSalva="capturarMensagemPersonalizada"
        :mensagemPadrão="mensagemPadrão"
        :mensagemAlterada="mensagemRenderizada"
        :idEmpresa="idEmpresa"
        @voltarMsgPadrao="voltarMsgPadrao"
      ></chat-bot-vue>
      <div class="caixaTitulo_titulo">Chatbot</div>
      <div></div>
    </header>
    <section class="conteudo">
      <div class="tabela">
        <div style="display: flex">
        </div>
        <table cellspacing="0">
          <thead>
            <tr class="nomedascolunas">
              <th>Editar</th>
              <th style="text-align: center">
                <div>
                  <span class="style-name"
                    >Nome da mensagem
                  </span>
                </div>
              </th>
              <th>
                <span>Mensagem</span>
              </th>
            </tr>
          </thead>
          <tbody v-for="(row, index) in fixedDataChange" :key="index">
            <div class="quinaa"></div>
            <tr class="back-color">
              <td class="quina">
                <button style="width: 100%; cursor: pointer; border: none; background-color: transparent;" @click="openChatbot(row, index)">
                  <i class="fa fa-pencil"></i>
                </button>
              </td>
              <td>{{ row.nameMsg }}</td>
              <td class="style-msg">
                {{ truncateText(row.msgPersonalizada ? row.msgPersonalizada : row.msg, 80) }}
              </td>
            </tr>
            <tr class="line">
              <td colspan="8" class="naoSelecionavel">a</td>
            </tr>
          </tbody>
        </table>
        <div class="overlay" v-if="loading">
          <i id="icon-style" class="fa-solid fa-gear"></i>
          <i id="icon-stylee" class="fa-solid fa-gear"></i>
        </div>
      </div>
    </section>
  </main>
</template>
  
  
<style scoped>
.tabela {
  width: 100%;
  height: 70vh;
  font-family: Arial, Helvetica, sans-serif;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.overlay .fa-gear {
  animation: spin 1s linear infinite;
}
.style-msg {
  max-width: 18vw;
  overflow: hidden;
  min-width: 18vw;
  white-space: nowrap;
}
.style-name{
  margin-left: -19.3vw;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
#icon-style {
  align-items: center;
  font-size: 3rem;
  color: white;
}
#icon-stylee {
  font-size: 2rem;
  color: white;
  align-items: end;
}
h1 {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.caixaTitulo_botao:hover {
  background-color: #2bb542d7;
}

th,
td {
  padding: 5px;
  border-spacing: 35px;
}

.caixaTitulo_botao {
  position: relative;
  display: inline-block;
}

.caixaTitulo_botao:hover .DicaTexto {
  visibility: visible;
}

.icon-style {
  padding-left: 2.3vh;
}

.DicaTexto {
  position: absolute;
  visibility: hidden;
  font-size: 13px;
  width: 200px;
  background: rgba(37, 37, 37, 0.695);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 4px;
  bottom: 100%;
  left: 50%;
  margin-left: -100px;
  margin-bottom: 5px;
  transition-timing-function: ease;
  transition-duration: 0.2s;
}

.DicaTexto:after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(37, 37, 37, 0.695) transparent transparent transparent;
}

.back-color:hover {
  background-color: rgba(92, 90, 90, 0.1);
}

table {
  width: 100%;
}

thead {
  border-bottom: 1px solid #b3b3b3;
}

tr td {
  border-left: 1px solid #b3b3b3;
}

.nomedascolunas {
  text-align: left;
  color: #2bb542;
  font-family: initial;
}

thead {
  margin-bottom: 5px;
}

.nomedascolunas th {
  border-bottom: 1px solid #2bb542;
}

.pesquisa {
  width: 20%;
  height: 30px;
  padding-left: 5px;
  display: flex;
}

.line {
  padding: 0;
  color: transparent;
  border-left: none;
  border-right: none;
}

.line td {
  border-bottom: 1px solid #b3b3b3;
  border-left: none;
  height: 1px;
  padding: 0;
}

.line_vazia td {
  height: 1px;
  border: none;
}

.quina {
  width: 15px;
  text-align: center;
}
.quinaa{
  width: 4vw;
  text-align: center;
  border: none;
}

.botaoPesquisa {
  background-color: white;
  padding: 6px;
  border: 0px;
  cursor: pointer;
}

.nadaEncontrado {
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  text-align: center;
  color: #c1c1c1;
}

.btn_rm {
  font-size: 1rem;
  color: #2bb542;
  font-weight: bold;
  text-align: center;
  margin-left: 1.7vw;
}

.naoSelecionavel {
  font-size: 2px;
  color: transparent;

  -webkit-touch-callout: none;
  /* iPhone OS, Safari */
  -webkit-user-select: none;
  /* Chrome, Safari 3 */
  -khtml-user-select: none;
  /* Safari 2 */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+ */
  user-select: none;
}
</style>
  