import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import '@fortawesome/fontawesome-free/css/all.min.css';
import store from './store';
import createAxiosInstance from './config/axios-config';

const instanceAxios = createAxiosInstance(process.env.VUE_APP_ORIGIN);
const app = createApp(App);
app.config.globalProperties.$axios = instanceAxios;
app.use(router);
app.use(store);
app.mount('#app');
