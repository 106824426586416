<script>
const url = process.env.VUE_APP_API_URL;
//import this.$axios.get from "this.$axios.get";
import { postDisparo } from "./api";
import { AlertDataSend } from "../alert";
import ConfirmationModal from "../Comfirmacao/ConfirmationModal.vue"

export default {
  name: "Popup",
  components: { ConfirmationModal },

  props: {
    disparo: {},
    idQlik: {},
    idUser: {},
  },

  data() {
    return {
      listaDeDias: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
      hora: "08:00",
      disparoPeriodicoEntrada: "",
      disparoSemanal: [
        { diaSemana: "0", listaHorario: [] },
        { diaSemana: "1", listaHorario: [] },
        { diaSemana: "2", listaHorario: [] },
        { diaSemana: "3", listaHorario: [] },
        { diaSemana: "4", listaHorario: [] },
        { diaSemana: "5", listaHorario: [] },
        { diaSemana: "6", listaHorario: [] },
      ],
      confirmationDeleted: false,
      deletedAll: false,
    };
  },

  mounted() {
    let listaFiltrada = [];
    if (this.disparo.length > 0) {
      for (const item of this.disparo) {
        if (item.qlik === this.idQlik) {
          listaFiltrada.push(item);
        }
      }

      let listaParaAdicionar = [
        { diaSemana: "0", listaHorario: [] },
        { diaSemana: "1", listaHorario: [] },
        { diaSemana: "2", listaHorario: [] },
        { diaSemana: "3", listaHorario: [] },
        { diaSemana: "4", listaHorario: [] },
        { diaSemana: "5", listaHorario: [] },
        { diaSemana: "6", listaHorario: [] },
      ];

      for (const element of listaFiltrada) {
        listaParaAdicionar[element.dia_da_semana].listaHorario.push(
          element.hora_minuto
        );
      }
      this.disparoSemanal = listaParaAdicionar;
      this.verificarDisparoSemanal()
    }
    document.addEventListener('keydown', this.handleEscKey);
  },
  beforeDestroy() {
    // document.removeEventListener('click', this.handleClickOutside);
    document.removeEventListener('keydown', this.handleEscKey);
  },

  methods: {
    // Gera uma lista de horarios para o disparo periodico
    async disparoPeriodico() {
      switch (this.disparoPeriodicoEntrada) {
        case "0":
          this.disparoSemanal = this.montarDisparoUmaVezAoDia()
          break;
        case "1":
          this.disparoSemanal = this.montarDisparoHoraEmHora(this.disparoPeriodicoEntrada, this.hora)
          break;
        case "2":
          this.disparoSemanal = this.montarDisparoHoraEmHora(this.disparoPeriodicoEntrada, this.hora)
          break;
        default:
          AlertDataSend('Por favor selecione uma opção', 'alert')
      }
    },
    verificarDisparoSemanal() {
      const arrayEmpty = this.disparoSemanal.every(dia_da_semana => dia_da_semana.listaHorario.length === 0)
      return arrayEmpty
    },
    async postDisparofun() {
      if (this.disparoSemanal && !this.deletedAll) {
        const dataForSendDataBase = {
          disparo: this.disparoSemanal,
          idusuario: this.idUser,
          qlik: this.idQlik
        }
        await postDisparo(dataForSendDataBase)
        location.reload()
      } else if (this.deletedAll) {
        this.$axios.delete(url + '/shooting', {
          params: {
            id: this.idQlik
          }
        }).then(() => {
          location.reload()
        }).catch()

      } else {
        AlertDataSend('Por favor selecione uma opção!', 'alert')
      }
    },
    handleEscKey(event) {
      if (event.key === 'Escape') {
        this.$emit("fecharPopup");
      }
    },
    adicionarNovoHorario(index) {
      if (this.deletedAll) {
        this.deletedAll = false
      }
      this.disparoSemanal[index].listaHorario.push(this.hora);
    },

    fecharPopup() {
      this.$emit("fecharPopup");
    },

    removerColunaSemanal(index) {
      this.disparoSemanal[index].listaHorario = [];
    },
    removerLinhaSemanal(indexA, indexB) {
      this.disparoSemanal[indexA].listaHorario.splice(indexB, 1);
    },
    montarDisparoUmaVezAoDia() {
      if (this.deletedAll) {
        this.deletedAll = false
      }
      const novoDisparoSemanal = JSON.parse(JSON.stringify(this.disparoSemanal));
      const novoHorario = this.hora;
      for (let i = 0; i < novoDisparoSemanal.length; i++) {
        novoDisparoSemanal[i].listaHorario.push(novoHorario);
      }

      return novoDisparoSemanal;
    },
    montarDisparoHoraEmHora(intervalo, hora) {
      if (this.deletedAll) {
        this.deletedAll = false
      }
      const horaBase = parseInt(hora.slice(0, 2));
      const minutoBase = parseInt(hora.slice(3)).toString().padStart(2, '0');
      const objetoEstruturado = [
        { diaSemana: "0", listaHorario: [] },
        { diaSemana: "1", listaHorario: [] },
        { diaSemana: "2", listaHorario: [] },
        { diaSemana: "3", listaHorario: [] },
        { diaSemana: "4", listaHorario: [] },
        { diaSemana: "5", listaHorario: [] },
        { diaSemana: "6", listaHorario: [] },
      ]

      let listaDeHoras = [];

      for (let c = horaBase; c < 24; c += parseInt(intervalo)) {
        if (c < 10) {
          const horaMutada = '0' + c + ':' + minutoBase;
          listaDeHoras.push(horaMutada);
        } else {
          const horaMutada = c + ':' + minutoBase;
          listaDeHoras.push(horaMutada);
        }
      }
      for (let i = 0; i < objetoEstruturado.length; i++) {
        objetoEstruturado[i].listaHorario = listaDeHoras.slice()
      }
      return objetoEstruturado
    },

    async deletedConfirmed() {
      this.disparoSemanal = [
        { diaSemana: "0", listaHorario: [] },
        { diaSemana: "1", listaHorario: [] },
        { diaSemana: "2", listaHorario: [] },
        { diaSemana: "3", listaHorario: [] },
        { diaSemana: "4", listaHorario: [] },
        { diaSemana: "5", listaHorario: [] },
        { diaSemana: "6", listaHorario: [] },
      ]
      this.deletedAll = true
      this.confirmationDeleted = false
    },
    async apagarDisparoSair() {
      this.confirmationDeleted = true
    },
    deletedCanceled() {
      this.confirmationDeleted = false
    }
  },
};
</script>

<template>
  <main class="corpo-popup">
    <ConfirmationModal
      style="margin-right: 40vw"
      v-if="confirmationDeleted"
      :text="'Deseja realmente excluir tudo?'"
      @confirmed="deletedConfirmed"
      @canceled="deletedCanceled"
    ></ConfirmationModal>
    <section class="titulo-popup">
      <div class="text">
        <span>Agendar e receber as: </span>
        <input type="time" v-model="hora" />
        <select class="select_tempo" v-model="disparoPeriodicoEntrada">
          <option disabled hidden value="">Selecione uma opção</option>
          <option selected value="0">Uma vez ao dia</option>
          <option value="1">1 hora</option>
          <option value="2">2 horas</option>
        </select>
        <div>
          <button class="adc-style" @click="disparoPeriodico()">Aplicar</button>
        </div>
      </div>

      <div class="icon-delete" @click="fecharPopup()"><i style="color: red; font-size: 1.7rem;" class="fa-solid fa-xmark"></i></div>
    </section>
    <section class="conteiner-dia">
      <aside
        class="item-dia"
        v-for="(day, indexA) in disparoSemanal"
        :key="indexA"
      >
        <div
          :key="indexA"
          :class="{
            dia: true,
            'dia-ativo': disparoSemanal[indexA].listaHorario.length !== 0,
          }"
        >
          <label>
            <div @click="adicionarNovoHorario(indexA)">
              {{ listaDeDias[indexA] }}
            </div>
          </label>
        </div>
        <div style="height: 100%; width: 85px; overflow-y: scroll">
          <div
            v-for="(item, indexB) in day.listaHorario"
            :key="indexB"
            style="display: flex"
          >
            <span
              style="font-size: 0.8rem; cursor: pointer"
              @click="removerLinhaSemanal(indexA, indexB)"
            >
              <i id="icon" class="fa-solid fa-trash"></i
            ></span>
            <input type="time" v-model="day.listaHorario[indexB]" />
          </div>
        </div>
        <div class="space-ele">
          <label @click="adicionarNovoHorario(indexA)">
            <i
              class="fa-solid fa-plus"
              style="font-size: 1rem; cursor: pointer"
            ></i
          ></label>
          <span
            style="font-size: 0.9rem; cursor: pointer"
            @click="removerColunaSemanal(indexA, indexB)"
          >
            <i id="icon" class="fa-solid fa-trash"></i
          ></span>
        </div>
      </aside>
    </section>
    <section class="footer">
      <button class="botao-apagar" @click="apagarDisparoSair">
        Apagar tudo
      </button>
      <button class="botao-salvar" @click="postDisparofun()">Salvar</button>
    </section>
  </main>
</template>

<style scoped>
.titulo-popup {
  display: flex;
  font-size: 3vh;
  align-items: center;
  justify-content: space-between;
  height: 5vh;
  width: 100%;
}

.titulo-popup input {
  height: 4vh;
  width: 6vw;
  font-size: 2.3vh;
  border: 1px solid #929090;
  margin-left: 2%;
  border-radius: 5px;
}

.icon-delete {
  cursor: pointer;
  color: rgba(255, 0, 0, 0.832);
  margin-left: -30px;
  font: bold 0.5rem sans-serif;
  font-size: 1.5rem;
}
#icon {
  color: red;
  cursor: pointer;
  font-size: 0.8rem;
}

.text {
  display: flex;
  align-items: center;
  width: 90%;
  height: 100%;
}

.adc-style {
  border: 1px solid #929090;
  cursor: pointer;
  font-size: 1rem;
  margin-left: 35px;
  padding: 3px;
  background-color: white;
  border-radius: 5px;
}

.adc-style:hover {
  box-shadow: 1px 1px 5.5px #8b8b8b;
}

.select_tempo {
  color: black;
  height: 3.8vh;
  width: 11vw;
  background: white;
  border: 1px solid #929090;
  margin-left: 5%;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.item-dia {
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 15%; 
  max-width: 100px; 
}

i {
  font-size: 1px;
}

.conteiner-dia {
  display: flex;
  gap: 2vw;
  height: 70%;
  width: 80%; 
  max-width: 100%; 
  flex-wrap: nowrap; 
  justify-content: center; 

}

.dia label {
  display: block;
  width: 35px;
  font-size: 13px;
  text-align: center;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: #dddd;
  cursor: pointer;
  color: black;
}

label div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.dia-ativo label {
  background-color: #2bb542;
  color: white;
}

.botao-salvar {
  padding: 10px;
  width: 20%;
  background-color: #2bb542;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.botao-salvar:hover {
  box-shadow: 1px 1px 12.5px #8b8b8b;
}

.botao-apagar {
  padding: 10px;
  width: 20%;
  background-color: red;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.botao-apagar:hover {
  box-shadow: 1px 1px 12.5px #8b8b8b;
}

.corpo-popup {
  background-color: white;
  position: fixed;
  top: 17vw;
  left: 24.5vw;
  width: 58vw; 
  max-width: 90vw; 
  height: 50vh;
  box-shadow: 1px 1px 2px 2px rgb(217, 217, 217);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  padding: 30px;
  padding-top: 20px;
  padding-bottom: 10px;
}

.footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.space-ele {
  display: flex;
  gap: 1.3vw;
}
</style>